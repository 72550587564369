import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { BoxArrowInRight, PlusCircle } from "react-bootstrap-icons";
import axios from "axios";
import AuthContext from "../context/AuthContext"; // Assuming this is the correct path
import { createPopper } from "@popperjs/core";
import SpeechButton from "./SpeechButton";
import "./QuickTranslationTooltip.css";

const QuickTranslationTooltip = ({
  selectedWord,
  setSelectedWord,
  setWordForFullLookup,
  addUnknownWordToKnownWords,
  speakOnRender,
}) => {
  const context = useContext(AuthContext);
  const [fetchingQuickTranslation, setFetchingQuickTranslation] =
    useState(false);
  const [popperInstance, setPopperInstance] = useState(null);
  const [theMostLikelyTranslation, setTheMostLikelyTranslation] =
    useState(null);

  useEffect(() => {
    console.log(
      "Quick translation tooltip detected word change:",
      selectedWord
    );

    if (selectedWord && selectedWord.word && selectedWord.word.trim() !== "") {
      console.log(
        "Should show quick translation tooltip for the word: ",
        selectedWord
      );
      handlePopperTooltip(selectedWord);
    } else {
      // hide the tooltip
      hideTooltip();
    }
  }, [selectedWord]);

  function handlePopperTooltip(selectedWord) {
    console.log(
      "Checking if there's a highlighted word that corresponds to: ",
      selectedWord
    );
    const firstHighlightedElement = document.querySelector(".highlighted");
    if (firstHighlightedElement) {
      console.log("First highlighted element found:", firstHighlightedElement);
      // make tooltip visible
      document.querySelector("#tooltip").style.display = "block";
    } else {
      // REVIEW: not sure what to do here exactly
      console.log("No highlighted element found.");
    }
    const tooltip = document.querySelector("#tooltip");

    // Pass the button, the tooltip, and some options, and Popper will do the
    // magic positioning for you:
    if (firstHighlightedElement && tooltip) {
      const instance = createPopper(firstHighlightedElement, tooltip, {
        placement: "top",
        modifiers: [
          {
            name: "arrow",
            options: {
              element: document.querySelector("#arrow"),
            },
          },
        ],
      });
      setPopperInstance(instance);

      // Update the popper instance after setting the content
      if (!selectedWord.translation) {
        fetchQuickTranslation(selectedWord, instance);
      } else {
        document.querySelector("#translation").textContent =
          selectedWord.translation;
        instance.update();
        // set the tooltip visible
        tooltip.style.display = "block";
      }
    }
  }

  function hideTooltip() {
    document.getElementById("tooltip").style.display = "none";
  }

  // useEffect for fetchingQuickTranslation
  useEffect(() => {
    console.log("fetchingQuickTranslation changed: ", fetchingQuickTranslation);
    if (fetchingQuickTranslation && popperInstance) {
      popperInstance.update();
    }
  }, [fetchingQuickTranslation, popperInstance]);

  async function fetchQuickTranslation(selectedWord, popperInstance) {
    console.log("Fetching quick translation for word: ", selectedWord);
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );
    const tooltipTranslation = document
      .querySelector("#tooltip")
      .querySelector("#translation");
    tooltipTranslation.textContent = "";
    setFetchingQuickTranslation(true);
    popperInstance.update();

    try {
      const response = await axios.post("/api/translate-word-quickly-with-ai", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      });
      setFetchingQuickTranslation(false);
      console.log(
        "RESPONSE FROM TRANSLATE ONE WORD QUICKLY WITH AI: ",
        response.data
      );
      console.log("--- ORIGINAL WORD: ", response.data.original);
      console.log("--- LOCAL SELECTED WORD: ", selectedWord.word);
      // get the tooltip element
      console.log("POPPER: ", popperInstance);
      // set the translation to the tooltip
      // TODO: should actually rely only on the state and not set it manually
      tooltipTranslation.textContent =
        response.data.translation.the_most_likely_translation;
      setTheMostLikelyTranslation(
        response.data.translation.the_most_likely_translation
      );

      // Update the popper instance after setting the content
      popperInstance.update();
    } catch (error) {
      setFetchingQuickTranslation(false);
      console.error("Error fetching quick translation:", error);
    }
  }

  return (
    <div id="tooltip" role="tooltip">
      {console.log("Rendering QuickTranslationTooltip: ", selectedWord)}

      <div className="d-flex align-items-center">
        <div id="translationSpinner">
          {fetchingQuickTranslation && (
            <Spinner animation="grow" size="sm" className="me-2" />
          )}
        </div>
        <SpeechButton word={selectedWord?.word} speakOnRender={speakOnRender} />

        <div id="translation">I'm a tooltip</div>
        {/* TODO: showing it like this creates a delay and the update position happens before */}
        {!fetchingQuickTranslation && (
          <>
            {!selectedWord?.known && (
              <PlusCircle size="18"
                className="ms-3 tooltip-action-button"
                onClick={() => {
                  addUnknownWordToKnownWords(
                    selectedWord.word,
                    theMostLikelyTranslation,
                    0
                  );
                  hideTooltip();
                }}
              />
            )}
            <BoxArrowInRight size="20"
              className="ms-2 tooltip-action-button"
              onClick={() => {
                setWordForFullLookup(selectedWord);
                hideTooltip();
              }}
            />
          </>
        )}
      </div>
      <div id="arrow" data-popper-arrow></div>
    </div>
  );
};

export default QuickTranslationTooltip;
